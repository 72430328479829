import React, { useState, useEffect } from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import Checkbox from 'shared/form-input/checkbox';
import Card from 'shared/card';
import Button from 'button';
import axios from 'axios';

import s from './step5.module.scss';
import useGlobal from '@/hooks/useGlobal';
import '@firebase/storage';


export default function Step5({ goNext, submitURL, site, ...props }) {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { state, emit } = useGlobal();

  const sosArray = ['Deseo ser incluido en lista de espera para obtener un boton SOS gratuito'];
  const initCheckboxes = arr =>
  arr.reduce((acc, curr) => ({ [curr]: false, ...acc }), {});

  const [sos, setSos] = useState(
    initCheckboxes(sosArray)
  );

  const handleSubmit = async (e) => {
    e && e.preventDefault();
    setLoading(true);
    const data = {
      ...state.step1,
      ...state.step2,
      ...state.step3,
      ...state.step4,
      ...{ sos: sos['Deseo ser incluido en lista de espera para obtener un boton SOS gratuito'] },
    };
    console.log(data, 'data');
   axios.post(submitURL, data)
    .then((response) => {
      console.log(response);
    setLoading(false);
    setCompleted(true);
    })
    .catch((error) => {
      alert(error);
    });
  };

  useEffect(() => {
    async function submitForm() {
      await handleSubmit();
    }
    submitForm();
  }, []);

  return (
    <Container className={s.root}>
      <Card>
        {completed ? (
          <>
            <Headline underline center html="¡Felicidades!" />
            <p>
            ¡Felicidades! Ya forma parte del Programa de Asistencia Médica PAM del municipio
              {' '}
              {site}
. Estaremos A Tu Lado cuando más lo necesites.
            </p>
          </>
          )
        : (
          <form onSubmit={handleSubmit}>
            <Headline underline center html="Enviando Formulario, por favor espere..." h3 />
            {/* <div className={s.dropTarget}>
              <Img src="step5-hand" />
            </div> */}
            {/* {sosArray.map((name, i) => (
              <div className={`${s.checkboxWrapper} ${i % 2 !== 0 ? s.odd : ''}`}>
                <Checkbox
                  key={`${name}-${i}`}
                  name={name}
                  setter={setSos}
                  values={sos}
                  index={i}
                />
              </div>
            ))} */}
            {/* <Button className={s.button} type="submit" loading={loading}>
              Finalizar y Enviar
            </Button> */}
          </form>
      )}
      </Card>
    </Container>
  );
}
