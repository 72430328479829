import React from 'react';
import s from './card.module.scss';

export default function Card({ children, className, ...props }) {
return (
  <div className={`${s.wrapper} ${className || ''}`}>
    {children}
  </div>
);
}
