import React, { useCallback, useState, useEffect } from 'react';
import Container from 'container';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import Img from 'img';
import Headline from 'headline';
import Card from 'shared/card';
import Button from 'button';
import config from 'config/firebase';
import uid from 'uid';
import firebase from '@firebase/app';
import { PreviewImage } from './helpers';
import useGlobal from '@/hooks/useGlobal';
import s from './step4.module.scss';

const firebaseApp = firebase.initializeApp(config);

export default function Step4({ isBaruta, goNext, pdfPath, programa, bucket, ...props }) {
  const { state, emit } = useGlobal();
  const [droppedFiles, setDroppedFiles] = useState();
  const [loading, setLoading] = useState(false);
  const previewImages = state.uploadedImages ? [...droppedFiles, ...state.uploadedImages] : droppedFiles;

  const onDrop = useCallback((item, monitor) => {
    if (monitor) {
      const { files } = monitor.getItem();
      setDroppedFiles(files);
    }
  }, []);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      onDrop(props, monitor);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    console.log(droppedFiles, 'droppedFiles');
    console.log(canDrop, 'canDrop');
    console.log(isOver, 'isOver');
  }, [droppedFiles, canDrop, isOver]);

  const handleChange = (e) => {
    setDroppedFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    emit.update({ step4: droppedFiles });
    setLoading(true);
    try {
      const id = uid(32);
      const storage = firebaseApp.storage();
      const uploadingFiles = droppedFiles.map(async (file, idx) => {
        const uploadRef = storage.ref(bucket).child(`${id}${idx}`);
        const snapshot = await uploadRef.put(file);
        const downloadUrl = await snapshot.ref.getDownloadURL();
        return downloadUrl;
      });
      const uploadedImages = await Promise.all(uploadingFiles);
      emit.update({ step4: uploadedImages });
      goNext();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = async (idx, file) => {
    setDroppedFiles([...droppedFiles.slice(0, idx), ...droppedFiles.slice(idx + 1)]);
  };

  return (
    <Container className={s.root}>
      <Card>
        {loading ? (
          <Headline underline center h3 html="Cargando archivo, por favor espere..." />
        ) : (
          <form onSubmit={handleSubmit}>
            <Headline underline center html="Adjuntar imagen" h3 />
            <p>Por favor, adjunte una fotografía o escaneo de su cédula de identidad.</p>
            <div ref={drop} className={s.dropTarget}>
              <Img src="dropbg" />
              <input type="file" onChange={handleChange} className={s.fileInput} />
            </div>
            <div className={s.previews}>
              {previewImages?.map((file, idx) => (
                <PreviewImage key={`${idx}-${Math.random()}`} file={file} deleteImage={() => deleteImage(idx, file)} />
              ))}
            </div>
            {isBaruta && (
            <div className={s.menorEdad}>
              <p className={s.TamLetra}>
                ** En caso de ser menor de edad, ingresar cédula de su
                representante
              </p>
            </div>
          )}
            <p>
              Al suscribirse usted acepta los
              {' '}
              <a className={s.terminosCond} target="_blank" rel="noopener noreferrer" href={pdfPath}>
                Términos y Condiciones
              </a>
              {' '}
              del programa
              {' '}
              {programa}
.
            </p>
            <Button className={s.button} type="submit" disabled={!droppedFiles?.length > 0}>
              Finalizar y enviar formulario
            </Button>
          </form>
        )}
      </Card>
    </Container>
  );
}
