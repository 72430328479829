/* eslint jsx-a11y/anchor-has-content: 0 */
/* eslint react/button-has-type: 0 */
import React from 'react';
import { Link } from 'gatsby';
import s from './button.module.scss';

export default ({
  href,
  native,
  children,
  className,
  secondary,
  tertiary,
  big,
  small,
  outline,
  type,
  html,
  lp,
  reg,
  white,
  pulse,
  ...props
}) => {
  const secondaryClasses = secondary ? s.secondary : '';
  const tertiaryClasses = tertiary ? s.tertiary : '';
  const lpClass = lp ? s.lp : '';
  const bigClass = big ? s.big : '';
  const pulseClass = pulse ? s.pulse : '';
  const smallClass = small ? s.small : '';
  const outlineClass = outline ? s.outline : '';
  const sizeClasses = bigClass || smallClass;
  const classes = `${s.default} ${className ||
    ''} ${secondaryClasses} ${tertiaryClasses} ${sizeClasses} ${lpClass} ${reg ? s.reg : ''} ${white ? s.white : ''} ${outlineClass} ${pulseClass}`;

  const buttonProps = html
    ? {
        ...props,
        dangerouslySetInnerHTML: { __html: html }
      }
    : {
        ...props,
        children
      };

  if (href && native) {
    return <a className={classes} href={href} name={href} {...buttonProps} />;
  }
  if (href) {
    return <Link {...buttonProps} to={href} className={classes} />;
  }
  return (
    <button type={type || 'button'} {...buttonProps} className={classes} />
  );
};
