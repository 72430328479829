import React from 'react';
import useGlobal from '@/hooks/useGlobal';
import Form from './form';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';

const sites = {
  baruta: {
    step1: {
      sectores: [
          'POBLACION BARUTA ',
          'CHARALLAVITO',
          'CHUAO',
          'COLINAS DE BELLO MONTE',
          'EL PEÑON-STA. GERTRUDRIS',
          'LA TRINIDAD-SOROKAIMA',
          'LAS MERCEDES',
          'LOMAS DEL CLUB HIPICO',
          'LOS CAMPITO-EL VIÑEDO',
          'PRADOS DEL ESTE-LAS COCUIZAS',
          'SANTA FE (ESTE)',
          'VALLE ARRIBA',
          'SANTA MARTA',
          'MONTE ELENA',
          'STA. FE -LAS CAROLINAS-SAN JUAN-TERRAZAS',
          'SAN ROMAN-EL MIRADOR',
          'LOS NARANJOS DE LAS MERCEDES',
          'LOMAS DEL MIRADOR',
          'LA PEÑA DE LAS MERCEDES',
          'COLINAS DE VALLE ARRIBA -LA ALAMEDA',
          'COLINAS DEL TAMANACO - VIZCAYA',
          'CUMBRES DE CURUMO',
          'PIEDRA AZUL',
          'ALTO PRADO',
          'STA. ROSA DE LIMA-LAS MESETAS',
          'MONTERREY',
          'LOMAS DE LOS MANGOS',
          'LOS GUAYABITOS',
          'TERRAZAS DEL CLUB HIPICO-BRISAS DEL PRADO-LOS RISCOS',
          'EL PLACER',
          'EL CERRITO',
          'STA.SOFIA',
          'SAN LUIS',
          'STA.PAULA',
          'STA. INES-LAS NAYAS-TERRAZAS DE LO S ALPES',
          'CERRO QUINTERO-LA CALERA',
          'EL REFUGIO-VALLE ALTO',
          'PARQ. HUMBOLDT',
          'LAS MINAS DE BARUTA',
          'MONTE PINO',
          'MONTE ALTO',
          'GUAICAY-LAS LOMAS-LA BONITA',
          'MAGNUM',
          'LAS ESMERALDAS-LA TAHONA',
          'MANZANARES',
          'VILLA DEL ESTE',
          'LOS SAMANES',
          'LOMAS DE TERRRABELLA / LA ARBOLEDA',
          'PARQUE EL INDIO',
          'EL GAVILAN',
          'PARIAGUAN. BARUTA - GAVILAN',
          'ALTOS DE SURIMA / BOSQUE AVILA VILLAS',
          'BOSQUE LOS NARANJOS',
          'TOPOS LOS MANGOS',
          'SISIPA',
          'CONJ. RES. BOSQUE REAL',
          'HOYO DE LA PUERTA / BOQUERÓN, LA SALSA, POUCA',
          'SECTOR CURUMO',
          'TURGUA',
          'FINCA GAMELOTAL / FINCA LA CHIVERA',
          'LOS HORNITOS / ALTOS DE SAN GABRIEL-CASA DE CAMPO',
          'GRANJERIAS DE LA TRINIDAD',
          'LOMAS DE LA ALAMEDA / LOMAS DE VALLE ARRIBA',
          'MIRADOR DE LOS CAMPITOS',
          'PARCELAMIENTO ARBOLAVILA',
          'ALTOS DE LA LOMA',
          'HACIENDA SAN ANDRÉS',
          'HACIENDA LA MATA',
          'BLE O TINOCO',
          'INGENIO. LA CALERA',
          'SECTOR LA GUARITA',
          'CONJ. RES. ALTOS DEL MIRADOR (CALLE LA COLINA/ LOMA DEL MIRADOR',
          'SUC. ARRAIZ',
          'VILLAS DEL ESTE / AGUA CLARA',
          'CAURIMARE',
          'CAFETAL / SANTA ANA / SANTA CLARA',
          'LOMAS DE MATURIN',
      ],
      isBaruta: true
    },
    step2: {},
    step3: {},
    step4: {
      programa: 'PAM-Baruta',
      pdfPath: '/b-terminos-y-condiciones-pam-baruta.pdf',
      bucket: 'baruta-form-cedulas',
      isBaruta: true
    },
    step5: { submitURL: 'https://gno-web.firebaseio.com/baruta-form-pipe.json', site: 'Baruta' },
  },
  elhatillo: {
    step1: {
      sectores: [
        'Pueblo de El Hatillo',
        'El Calvario',
        'El Arroyo',
        'Las Marías',
        'La Lagunita',
        'Alto Hatillo',
        'Oripoto',
        'Cerro Verde',
        'Llano Verde',
        'Los Pomelos',
        'Los Naranjos',
        'Lomas del Sol',
        'El Solar Del Hatillo',
        'La Toma',
        'La Boyera',
        'El Cigarral',
        'Los Geranios',
        'La Cabaña',
        'Los Pinos',
        'El Encantado',
        'Lomas de La Lagunita',
        'Caicaguana',
        'Bosques de La Lagunita',
        'Altos de Villanueva',
        'Loma Linda',
        'La Unión',
        'Los Robles',
        'Corralito',
        'Gavilán ',
        'La Mata ',
        'Sabeneta',
        'La Hoyadita',
        'Turgua',
      ],
    },
    step2: {},
    step3: {},
    step4: {
      programa: 'PAM-El Hatillo',
      pdfPath: '/terminos-y-condiciones-del-pam-el-hatillo.pdf',
      bucket: 'elhatillo-form-cedulas',
    },
    step5: { submitURL: 'https://gno-web.firebaseio.com/elhatillo-form-pipe.json', site: 'El Hatillo' },
  },

  redirectBaruta: {
    step1: {},
    step2: {},
    step3: {},
    step4: {
      programa: 'PAM-Baruta',
      pdfPath: '/a.TERMINOS Y CONDICIONES PAM BARUTA.pdf',
      bucket: 'baruta-form-cedulas',
      isBaruta: true
    },
    step5: { submitURL: 'https://gno-web.firebaseio.com/baruta-form-pipe.json', site: 'Baruta' },
  }
};

const siteData = sites[process.env.GATSBY_SITE];

export default function PageHome() {
  const { emit, state } = useGlobal();

  function goNext() {
    emit.updateCb((prevState) => ({ ...prevState, step: prevState.step + 1 }));
  }

  function renderStep() {
    switch (state.step) {
      case 1:
        return <Form goNext={goNext} {...siteData.step1} />;
      case 2:
        return <Step2 goNext={goNext} {...siteData.step2} />;
      case 3:
        return <Step3 goNext={goNext} {...siteData.step3} />;
      case 4:
        return <Step4 goNext={goNext} {...siteData.step4} />;
      case 5:
        return <Step5 goNext={goNext} {...siteData.step5} />;
      default:
        return <Form goNext={goNext} {...siteData.step1} />;
    }
  }

  return <main>{renderStep()}</main>;
}
