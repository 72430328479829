import { useState, useEffect, useMemo } from 'react';
import globalValidate, { validateStore } from 'utils/validate';
import useStore from 'hooks/useStore';

// custom hook for form validations.
export const useFormInput = (initialValue, cbs = {}) => {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(!!initialValue);
  const [blurred, setBlurred] = useState(!!initialValue);
  const [callbacks, setCallbacks] = useState({
    onChange: () => null,
    onBlur: () => null,
    ...cbs
  });

  const onChange = e => {
    setValue(e.target.value);
    if (!touched) setTouched(true);
    callbacks.onChange();
  };

  const onBlur = () => {
    if (!blurred && touched) {
      setBlurred(true);
      callbacks.onBlur();
    }
  };

  const clearValue = () => setValue('');

  return {
    clearValue,
    events: {
      onChange,
      onBlur
    },
    state: {
      value,
      touched,
      blurred
    },
    setters: {
      setValue,
      setTouched,
      setBlurred,
      setCallbacks
    }
  };
};

export const useErrors = (inputs, validation) => {
  const validate = validation || globalValidate;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const values = Object.entries(inputs)
      .reduce((acc, curr) => [...acc, ...curr], [])
      .filter(val => typeof val === 'object' && !Array.isArray(val))
      .filter(val => val.blurred && val.touched);
    if (values.length) {
      setErrors(validate(inputs));
    }
    if (values.length === 0 && !!Object.keys(errors)[0]) {
      setErrors({});
    }
  }, [JSON.stringify(inputs)]);
  return errors;
};

export const useSubmitReady = (inputs, validation) => {
  const validate = validation || globalValidate;
  const [submitReady, setSubmitReady] = useState(false);

  const handleSubmitReady = () => {
    const allTouched =
      Object.keys(inputs)
        .map(value => inputs[value].touched)
        .filter(value => !value).length === 0;
    const noErrors = !Object.keys(validate(inputs)).length;
    setSubmitReady(allTouched && noErrors);
  };

  useEffect(() => {
    handleSubmitReady();
  }, [JSON.stringify(inputs)]);
  return submitReady;
};

const useSubmitReadyStore = store => {
  const { state, emit } = store;
  const validate = validateStore;

  const handleSubmitReady = () => {
    const allTouched =
      state.required.map(value => state.touched[value]).filter(value => !value)
        .length === 0;
    const noErrors = !Object.keys(validate(state, { noBlur: true })).length;
    emit.update({
      submitReady: allTouched && noErrors
    });
  };

  useEffect(() => {
    handleSubmitReady();
  }, [JSON.stringify(state || {})]);
};

const useErrorsStore = store => {
  const { state, emit } = store;
  const validate = validateStore;
  useEffect(() => {
    const inputs = state.required.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: state.inputs[curr]
      };
    }, {});
    const values = Object.keys(inputs || {}).filter(key => {
      const isBlurred = state.blurred[key];
      const isTouched = state.touched[key];
      return isBlurred && isTouched;
    });
    if (values.length) {
      emit.update({
        errors: validate(state)
      });
    }
    if (values.length === 0 && !!Object.keys(state.errors || {})[0]) {
      emit.update({
        errors: {}
      });
    }
  }, [JSON.stringify(state || {})]);
};

export default (initialInputs = {}, required = []) => {
  const initialState = useMemo(
    () => ({
      inputs: {
        ...initialInputs
      },
      touched: {},
      blurred: {},
      errors: {},
      submitReady: false,
      required
    }),
    []
  );
  const store = useStore(initialState);
  useErrorsStore(store);
  useSubmitReadyStore(store);
  const { state, emit } = store;

  const resetForm = () => {
    emit.update(initialState);
  };

  const onChange = e => {
    const { name, value } = e.target;
    emit.updateCb(currentState => {
      return {
        inputs: {
          ...currentState.inputs,
          [name]: value
        }
      };
    });
    const touched = state.touched[name];
    if (!touched) {
      emit.updateCb(currentState => {
        return {
          touched: {
            ...currentState.touched,
            [name]: true
          }
        };
      });
    }
  };

  const onBlur = e => {
    e.persist();
    const { name } = e.target;
    const touched = state.touched[name];
    const blurred = state.blurred[name];
    if (!blurred && touched) {
      emit.updateCb(currentState => {
        return {
          blurred: {
            ...currentState.blurred,
            [name]: true
          }
        };
      });
    }
  };

  return {
    events: {
      onChange,
      onBlur
    },
    resetForm,
    ...store
  };
};
