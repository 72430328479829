import React, { useState } from 'react';
import s from './form-input.module.scss';

export default function FormInput({
  customActive,
  className,
  required,
  textarea,
  round,
  error,
  id,
  white,
  errormsg,
  ...props
}) {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`${s.root} ${className || ''} ${error ? s.error : ''} ${
        white ? s.white : ''
      }`}
    >
      {textarea ? (
        <label htmlFor={id}>
          {(!active && !customActive) && (
            <span className={s.label}>
              {required ? `${props.placeholder} *` : props.placeholder}
            </span>
          )}
          <textarea onInput={e => setActive(e.target.value !== '')} className={white ? s.white : ''} id={id} {...props} />
        </label>
      ) : null}
      {round ? (
        <label htmlFor={id}>
          <input
            placeholder={
              required ? `${props.placeholder} *` : props.placeholder
            }
            required={required}
            id={id}
            {...props}
            className={s.round}
          />
        </label>
      ) : null}
      {!textarea && !round ? (
        <label htmlFor={id}>
          {(!active && !customActive && (!error || !errormsg)) && (
            <span className={s.label}>
              {required ? `${props.placeholder} *` : props.placeholder}
            </span>
          )}
          <input required={required} onInput={e => setActive(e.target.value !== '')} id={id} {...props} className={`${active && s.active} ${white ? s.white : ''}`} />
          {error && errormsg && <p className={`error ${s.errortag}`}>{error}</p>}
        </label>
      ) : null}
    </div>
  );
}
