import React, { useState, useEffect } from 'react';
import Container from 'container';
import axios from 'axios';
import Button from 'button';
import Input from 'form-input';
import Select from 'form-input/select';
import Card from 'shared/card';
import Headline from 'headline';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import ReactTooltip from 'react-tooltip';
import useGlobal from '@/hooks/useGlobal';
import s from './form.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

export default function Form({ sectores, isBaruta, goNext, ...props }) {
  const { state, emit } = useGlobal();

  const [active, setActive] = useState(false);
  const [error, setError] = useState(false);

  // Inputs
  const name = useFormInput('');
  const lastName = useFormInput('');
  const [sex, setSex] = useState('Masculino');
  const [dniPrefix, setDniPrefix] = useState('V');
  // const date = useFormInput('');
  const dni = useFormInput('');
  const email = useFormInput('');
  const phone = useFormInput('');
  const address = useFormInput('');
  const [sector, setSector] = useState(false);
  const [pickedDate, setPickedDate] = useState(null);

  // Errors
  const nameErrors = useErrors({ name: name.state });
  const lastNameErrors = useErrors({ lastName: lastName.state });
  // const dateErrors = useErrors({ date: date.state });
  const dniErrors = useErrors({ dni: dni.state });
  const emailErrors = useErrors({ email: email.state });
  const phoneErrors = useErrors({ phone: phone.state });
  const addressErrors = useErrors({ address: address.state });

  const submitReady = useSubmitReady({
    name: name.state,
    lastName: lastName.state,
    dni: dni.state,
    // date: pickedDate.state,
    email: email.state,
    phone: phone.state,
    address: address.state,
  });

  function handleDateChange(e) {
    setActive(e.target.value !== '');
    let output = '';
    const dd = e.target.value.substring(0, 2);
    const mm = e.target.value.substring(3, 5);
    const yyyy = e.target.value.substring(6, 10);

    if (dd.length < 2) {
      output = dd;
    } else if (dd.length === 2 && mm.length < 2) {
      output = `${dd}/${mm}`;
    } else if (dd.length === 2 && mm.length === 2 && yyyy.length < 5) {
      output = `${dd}/${mm}/${yyyy}`;
    }

    e.target.value = output;
    return output;
  }

  function handleErase(e) {
    let output = '';
    const dd = e.target.value.substring(0, 2);
    const mm = e.target.value.substring(3, 5);
    const yyyy = e.target.value.substring(6, 10);

    console.log(e.key);
    if (e.key === 'Backspace') {
      if (dd.length === 2 && mm.length === 0) {
        output = `${dd}`;
        e.target.value = output;
      } else if (dd.length === 2 && mm.length === 2 && yyyy.length === 0) {
        output = `${dd}/${mm}`;
        e.target.value = output;
      }
    }
    return output;
  }

  useEffect(() => {
    if (sector) {
      setError(false);
    }
  }, [sector]);

  function formatDate(date) {
    return (date.getDate().toString().length == 2 ? date.getDate().toString() : ('0' + date.getDate().toString())) + '-' +
      ((date.getMonth() + 1).toString().length == 2 ? (date.getMonth() + 1).toString() : ('0' + (date.getMonth() + 1).toString())) + '-' +
      date.getFullYear().toString();
  }

  function formatTime(date) {
    return date.getHours().toString() + ':' + date.getMinutes().toString() + ':' + date.getSeconds().toString();
  }

  function handleDNIChange(e) {
     let output = '';
    output = e.target.value;
    output = output.replace(/[^0-9]/, '');
    e.target.value = output;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!submitReady) {
      return;
    }
    if (sectores && !sector) {
      setError('Por favor seleccione un sector.');
      return;
    }
    const date = new Date();
    const payload = {
      name: name.state.value,
      lastName: lastName.state.value,
      sex,
      // date: date.value?.replace(/\//gim, '-'),
      date: formatDate(pickedDate),
      dni: `${dniPrefix}-${dni.state.value}`,
      email: email.state.value,
      phone: phone.state.value,
      address: address.state.value,
      sector: sectores && sector,
      registro: formatDate(date) + ' ' + formatTime(date),
    };

    emit.update({ step1: payload });
    goNext();

    const inputs = [name, lastName, dni, email, phone, address];
    inputs.forEach((input) => {
      input.setters.setValue('');
      input.setters.setTouched(false);
      input.setters.setBlurred(false);
    });
  };

  return (
    <Container className={s.root}>
      <Card>
        <Headline
          className={s.headline}
          underline
          center
          html="Datos personales"
          h3
        />
        <form className={s.form} onSubmit={handleSubmit}>
          <Input
            placeholder="Nombre"
            type="text"
            name="name"
            required
            value={name.state.value}
            {...name.events}
            error={nameErrors.name}
            className={s.input}
          />
          <Input
            placeholder="Apellido"
            type="text"
            name="lastName"
            required
            value={lastName.state.value}
            {...lastName.events}
            error={lastNameErrors.lastName}
            className={s.input}
          />
          <Select
            className={s.select}
            value={sex}
            onChange={(e) => setSex(e.target.value)}
          >
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
          </Select>
          <div className={s.datePickerWrapper}>
            <DatePicker
              autoComplete="off"
              locale="es"
              customActive={active}
              setCustomActive={setActive}
              required
              dateFormat={["dd/MM/yyyy", "ddMMyyyy"]}
              maxDate={new Date()}
              onChange={setPickedDate}
              errormsg
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              name="date"
              placeholderText="Fecha de Nacimiento *"
              selected={pickedDate}
              // {...date.events}
              // error={dateErrors.date}
            />
          </div>
          <div
            className={s.cedulaWrapper}
          >
            <Select
              className={s.dniType}
              value={dniPrefix}
              onChange={(e) => setDniPrefix(e.target.value)}
            >
              <option value="V">V</option>
              <option value="E">E</option>
              <option value="J">J</option>
              <option value="C">C</option>
              <option value="G">G</option>
            </Select>
            <Input
              placeholder="Cédula"
              type="text"
              onKeyUp={handleDNIChange}
              pattern="[0-9]{1,9}"
              name="dni"
              required
              value={dni.state.value}
              {...dni.events}
              error={dniErrors.dni}
              className={s.inputCedula}
              data-for="dni-input"
              data-tip="Por favor introduzca únicamente dígitos<br />En caso de ser menor de edad, ingresar cédula de su representante"
              data-event="hover touch click active"
            />
            <ReactTooltip
              id="dni-input"
              place="right"
              effect="solid"
              multiline="true"
            />
          </div>
          {isBaruta && (
            <div className={s.menorEdad}>
              <p className={s.TamLetra}>
                ** En caso de ser menor de edad, ingresar cédula de su
                representante
              </p>
            </div>
          )}
          <Input
            placeholder="Correo"
            type="email"
            name="email"
            required
            errormsg
            value={email.state.value}
            {...email.events}
            error={emailErrors.email}
            className={s.input}
          />
          <Input
            placeholder="Teléfono celular"
            type="text"
            name="phone"
            required
            errormsg
            value={phone.state.value}
            {...phone.events}
            error={phoneErrors.phone}
            className={s.input}
            data-for="phone-input"
            data-tip="Por favor introduzca únicamente dígitos"
            data-event="hover touch click active"
          />
          <ReactTooltip
            id="phone-input"
            place="right"
            effect="solid"
            multiline="true"
          />
          <Input
            placeholder="Dirección"
            type="text"
            name="address"
            required
            value={address.state.value}
            {...address.events}
            error={addressErrors.address}
            className={`${s.inputDireccion} ${sectores && s.mb20}`}
          />
          {error && <p className="error">{error}</p>}
          {sectores && (
            <Select
              className={s.inputDireccion}
              value={sector}
              onChange={(e) => setSector(e.target.value)}
            >
              <option value="">Seleccione un sector/urbanización *</option>
              {sectores?.map((sector) => (
                <option value={sector}>{sector}</option>
              ))}
            </Select>
          )}
          <Button className={s.button} type="submit">
            Continuar
          </Button>
        </form>
      </Card>
    </Container>
  );
}
