const validate = values => {
  const errors = {};

  function validateText(val, message) {
    if (values[val]?.touched) {
      if (!values[val].value) {
        errors[val] = message || `Your ${val} is required`;
      }
    }
  }

  if (values.email?.touched) {
    if (!values.email.value) {
      errors.email = 'Este email es requerido';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email.value)
    ) {
      errors.email = 'El email suministrado es inválido';
    }
  }

  // console.log(values.date);

  // if (values.date?.touched) {
  //   if (!values.date.value) {
  //     errors.date = 'Introduzca fecha en el formato dd/mm/yyyy';
  //   } else if (
  //     !/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/i.test(values.date.value)
  //   ) {
  //     errors.date = 'Introduzca fecha en el formato dd/mm/yyyy';
  //   }
  // }


  validateText('name');
  validateText('firstName');
  validateText('lastName');
  validateText('emergName1');
  validateText('emergName2');
  validateText('emergLastName1');
  validateText('emergLastName2');
  validateText('address');
  validateText('dni');
  validateText('apt');
  validateText('companyName');
  validateText('companyAddress1');
  validateText('city');
  // validateText('phone');
  // validateText('emergPhone1');
  // validateText('emergPhone2');
  validateText('phone');

  validateText('msg');
  validateText('review', 'A review is required');

  if (values.password?.touched) {
    if (!values.password.value) {
      errors.password = 'Please confirm password';
    } else if (!(values.password.value && values.password.value.length > 5)) {
      errors.password = 'Password must have at least 6 characters';
    }
  }

  if (values.passwordConfirm?.touched) {
    if (!values.passwordConfirm.value) {
      errors.passwordConfirm = 'Please confirm your new password';
    }
  }

  // if (values.dni?.touched) {
  //   if (!values.dni.value) {
  //     errors.dni = 'Please, your DNI is required';
  //   } else if (!/^[0-9]{1,9}$/.test(String(values.dni.value))) {
  //     errors.dni = 'Número de cédula inválido';
  //   }
  // }

  if (values.zip?.touched) {
    if (!values.zip.value) {
      errors.zip = 'Your zip code is required';
    } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(String(values.zip.value))) {
      errors.zip = 'Invalid zip code';
    }
  }

  if (values.msg?.touched) {
    if (!values.msg.value) {
      errors.msg = 'A message is required';
    }
  }

  function validatePhone(val, message1, message2) {
    if (values[val]?.touched) {
      if (!values[val].value) {
        errors[val] = message1 || 'Este número de teléfono es requerido';
      } else if (
        !/^[0-9]{11}$/.test(
          String(values[val].value)
        )
      ) {
        errors[val] = message2 || 'El número de teléfono suministrado es inválido';
      }
    }
  }

  validatePhone('phone');
  validatePhone('emergPhone1');
  validatePhone('emergPhone2');


  return errors;
};

function canValidate(store, field, options) {
  const defaultOptions = store.touched[field] && store.required.includes(field);
  return options.noBlur
    ? defaultOptions && store.blurred[field]
    : defaultOptions;
}

export const validateStore = (store, options = {}) => {
  const errors = {};

  function validateText(val, message) {
    if (canValidate(store, val, options)) {
      if (!store.inputs[val]) {
        errors[val] = message || `Your ${val} is required`;
      }
    }
  }

  validateText('firstName');
  validateText('lastName', 'A last name is required');
  validateText('address1', 'A street address is required');
  validateText('province', 'A state is required');
  validateText('city', 'A city is required');
  validateText('country', 'A country is required');
  validateText('password', 'Your password is required');

  if (canValidate(store, 'email', options)) {
    if (!store?.inputs?.email) {
      errors.email = 'Your email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(store.inputs.email)
    ) {
      errors.email = 'Invalid email address';
    }
  }

  if (canValidate(store, 'zip', options)) {
    if (!store?.inputs?.zip) {
      errors.zip = 'A ZIP code is required';
    } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(String(store?.inputs?.zip))) {
      errors.zip = 'Invalid zip code';
    }
  }

  return errors;
};

export default validate;
