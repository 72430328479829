import React, { useState } from 'react';
import Container from 'container';
import axios from 'axios';
import Button from 'button';
import Input from 'form-input';
import Select from 'form-input/select';
import Card from 'shared/card';
import Headline from 'headline';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import ReactTooltip from 'react-tooltip';
import useGlobal from '@/hooks/useGlobal';
import s from './form.module.scss';

export default function Form({ data, goNext, ...props }) {
  const { state, emit } = useGlobal();

  // Emergency Inputs 1
  const emergName1 = useFormInput('');
  const emergLastName1 = useFormInput('');
  const emergPhone1 = useFormInput('');

  // Emergency Inputs 2
  const emergName2 = useFormInput('');
  const emergLastName2 = useFormInput('');
  const emergPhone2 = useFormInput('');

  // Emergency Errors 1
  const emergNameErrors1 = useErrors({ emergName1: emergName1.state });
  const emergLastNameErrors1 = useErrors({
    emergLastName1: emergLastName1.state,
  });
  const emergPhoneErrors1 = useErrors({ emergPhone1: emergPhone1.state });

  // Emergency Errors 2
  const emergNameErrors2 = useErrors({ emergName2: emergName2.state });
  const emergLastNameErrors2 = useErrors({
    emergLastName2: emergLastName2.state,
  });
  const emergPhoneErrors2 = useErrors({ emergPhone2: emergPhone2.state });

  const submitReady = useSubmitReady({
    emergName1: emergName1.state,
    emergLastName1: emergLastName1.state,
    emergPhone1: emergPhone1.state,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!submitReady) {
      return;
    }
    const payload = {
      emergencyContact1: {
        emergName1: emergName1.state.value,
        emergLastName1: emergLastName1.state.value,
        emergPhone1: emergPhone1.state.value,
      },
      emergencyContact2: {
        emergName2: emergName2.state.value,
        emergLastName2: emergLastName2.state.value,
        emergPhone2: emergPhone2.state.value,
      },
    };

    emit.update({ step2: payload });
    goNext();

    const inputs = [
      emergName1,
      emergLastName1,
      emergPhone1,
      emergName2,
      emergLastName2,
      emergPhone2,
    ];
    inputs.forEach((input) => {
      input.setters.setValue('');
      input.setters.setTouched(false);
      input.setters.setBlurred(false);
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const payload = {
  //     emergencyContact1: {
  //       emergName1: emergName1.state.value,
  //       emergLastName1: emergLastName1.state.value,
  //       emergPhone1: emergPhone1.state.value,
  //     },
  //     emergencyContact2: {
  //       emergName2: emergName2.state.value,
  //       emergLastName2: emergLastName2.state.value,
  //       emergPhone2: emergPhone2.state.value,
  //     },
  //   };
  //   console.log(payload);

  //   const url = 'https://baruta-form-poc.firebaseio.com/form';
  //   await axios(url, {
  //     headers: { 'Content-Type': 'application/json' },
  //     method: 'POST',
  //     data: payload,
  //   })
  //     .then((x) => {
  //       console.log(x);
  //       navigate('/thanks/');
  //     })
  //     .catch((err) => console.log(err));

  //   const inputs = [name, email];
  //   inputs.forEach((input) => {
  //     input.setters.setValue('');
  //     input.setters.setTouched(false);
  //     input.setters.setBlurred(false);
  //   });
  // };
  return (
    <Container className={s.root}>
      <Card>
        <Headline
          wrapClassName={s.wrapHeadline}
          className={s.headline}
          underline
          center
          html="Contactos de emergencia"
          h3
        />
        <form className={s.form} onSubmit={handleSubmit}>
          <div className={s.wrapperContactos}>
            <Headline
              wrapClassName={s.wrapSubtitle}
              className={s.subtitle}
              html="Contacto #1"
              h4
            />
            <Input
              required
              placeholder="Nombre"
              type="text"
              name="emergName1"
              value={emergName1.state.value}
              {...emergName1.events}
              error={emergNameErrors1.emergName1}
              className={s.input}
            />

            <Input
              required
              placeholder="Apellido"
              type="text"
              name="emergLastName1"
              value={emergLastName1.state.value}
              {...emergLastName1.events}
              error={emergLastNameErrors1.emergLastName1}
              className={s.input}
            />
            <Input
              required
              placeholder="Teléfono celular"
              type="phone"
              name=" emergPhone1"
              value={emergPhone1.state.value}
              {...emergPhone1.events}
              error={emergPhoneErrors1.emergPhone1}
              className={s.input}
              errormsg
              data-for="emerg-phone1"
              data-tip="Por favor introduzca únicamente dígitos"
              data-event="hover touch click active"
            />
            <ReactTooltip
              id="emerg-phone1"
              place="left"
              effect="solid"
              multiline="true"
            />
          </div>

          <div className={s.wrapperContactos}>
            <Headline
              wrapClassName={s.wrapSubtitle}
              className={s.subtitle}
              html="Contacto #2"
              h4
            />
            <Input
              placeholder="Nombre"
              type="text"
              name="emergName1"
              value={emergName2.state.value}
              {...emergName2.events}
              className={s.input}
            />

            <Input
              placeholder="Apellido"
              type="text"
              name="emergLastName1"
              value={emergLastName2.state.value}
              {...emergLastName2.events}
              className={s.input}
            />
            <Input
              placeholder="Teléfono celular"
              type="phone"
              name=" emergPhone2"
              value={emergPhone2.state.value}
              {...emergPhone2.events}
              error={emergPhoneErrors2.emergPhone2}
              className={s.input}
              data-for="emerg-phone2"
              data-tip="Por favor introduzca únicamente dígitos"
              data-event="hover touch click active"
            />
            <ReactTooltip
              id="emerg-phone2"
              place="left"
              effect="solid"
              multiline="true"
            />
          </div>

          <Button className={s.button} type="submit">
            Continuar
          </Button>
        </form>
      </Card>
    </Container>
  );
}
