import React from 'react';
import Icon from 'icon';
import s from '../form-input.module.scss';

export default function FormSelect({
  className,
  required,
  error,
  id,
  white,
  children,
  ...props
}) {
  return (
    <div
      className={`${s.root} ${className || ''} ${error ? s.error : ''} ${
        white ? s.white : ''
      }`}
    >
      <label htmlFor={id}>
        <span className={s.label}>
          {required ? `${props.placeholder} *` : props.placeholder}
        </span>
        <div className={s.selectWrapper}>
          <select {...props} className={white ? s.white : ''}>
            {children}
          </select>
        </div>
        {/* <Icon className={`${s.icon} ${s.flip}`} icon="angle-left" /> */}
      </label>
    </div>
  );
}
