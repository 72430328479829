import React from 'react';
import Meta from 'meta';
import Home from 'components/page-home';
import Card from 'components/shared/card';
import Container from 'components/shared/container';

import s from './index.module.scss';

export default function HomePage() {
  const sites = {
    baruta: {
      name: 'Baruta',
    },
    elhatillo: {
      name: 'El Hatillo',
    },
    redirectBaruta: {
      name: 'RedirectBaruta',
    },
  };
  const siteData = sites[process.env.GATSBY_SITE];
  return (
    <>
      <Meta
        title={`Programa de Asistencia Médica PAM del Municipio ${
          process.env.GATSBY_SITE === 'redirectBaruta'
            ? 'Baruta'
            : siteData.name
        }`}
      />
      {siteData.name === 'Baruta' || siteData.name === 'El Hatillo' ? (
        <Home />
      ) : (
        <Container className={s.bannerWrapper}>
          <div className={s.cardWrapper}>
            <Card className={s.card}>
              {process.env.GATSBY_SITE == 'redirectBaruta' && (
                <div
                  className={s.paragraph}
                  dangerouslySetInnerHTML={{
                    __html:
                      '<p>Para inscribirse en el Programa de Asistencia Médica PAM del municipio Baruta, por favor hacer click en el siguiente enlace:</p>',
                  }}
                />
              )}
              <a className={s.enlaceredirect} href="https://pambaruta.com/">
                pambaruta.com
              </a>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
}
