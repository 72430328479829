import React, { useState } from 'react';
import Container from 'container';
import Button from 'button';
import Input from 'form-input';
import Card from 'shared/card';
import Headline from 'headline';
import Checkbox from 'shared/form-input/checkbox';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import s from './form.module.scss';
import useGlobal from '@/hooks/useGlobal';

export default function Form({ data, goNext, ...props }) {
  const { state, emit } = useGlobal();

  const patologiasArray = [
    'Abortos', 'ACV', 'Amenorrea',
    'Angina', 'Arritmia', 'Artritis',
    'Asma', 'Bronquitis', 'Cefalea',
    'Diábetes', 'Enf. Ulcero Pépticas', 'Enfisema',
    'Epilepsia', 'ETS', 'Hepatitis',
    'Hernias', 'Hipotensión', 'HIV/SIDA',
    'HTA', 'ICC', 'IM',
    'IRA/IRC', 'Litiasis Biliar', 'Litiasis Renal',
    'Sinusitis', 'TBC', 'Trastorno Tiroideo',
    'Otras'
    ];

const initCheckboxes = arr =>
  arr.reduce((acc, curr) => ({ [curr]: false, ...acc }), {});
  const [patologias, setPatologias] = useState(
    initCheckboxes(patologiasArray)
  );

  // Habits and Medical History Inputs
  const tabaq = useFormInput('');
  const alcDrinks = useFormInput('');
  const others = useFormInput('');
  const actualMed = useFormInput('');
  const allergies = useFormInput('');
  const interventions = useFormInput('');

  // Habits and Medical History Errors
  const tabaqErrors = useErrors({ tabaq: tabaq.state });
  const alcDrinksErrors = useErrors({ alcDrinks: alcDrinks.state });
  const othersErrors = useErrors({ others: others.state });
  const actualMedErrors = useErrors({ actualMed: actualMed.state });
  const allergiesErrors = useErrors({ allergies: allergies.state });
  const interventionsErrors = useErrors({ interventions: interventions.state });

  const submitReady = useSubmitReady({

  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      tabaq: tabaq.state.value,
      alcDrinks: alcDrinks.state.value,
      others: others.state.value,
      actualMed: actualMed.state.value,
      allergies: allergies.state.value,
      interventions: interventions.state.value,
    };
    const parsedPatologias = Object.keys(patologias).reduce((acc, curr, i) => {
      let newCurr = false;
      if (curr === 'Diábetes') {
        newCurr = 'Diabetes';
      }
      if (curr === 'Enf. Ulcero Pépticas') {
        newCurr = 'enf-ulcero-pepticas';
      }
      if (curr === 'HIV/SIDA') {
        newCurr = 'hiv-sida';
      }
      if (curr === 'Hipotensión') {
        newCurr = 'hipotension';
      }
      if (curr === 'IRA/IRC') {
        newCurr = 'ira-irc';
      }
      if (curr === 'Litiasis Biliar') {
        newCurr = 'Litiasis-Biliar';
      }
      if (curr === 'Litiasis Renal') {
        newCurr = 'Litiasis-Renal';
      }
      if (curr === 'Trastorno Tiroideo') {
        newCurr = 'Trastorno-Tiroideo';
      }
      return {
        ...acc,
        [newCurr || curr]: patologias[curr]
      };
    }, {});
    emit.update({ step3: { ...payload, patologias: parsedPatologias } });
    goNext();

    const inputs = [
      tabaq,
      alcDrinks,
      others,
      actualMed,
      allergies,
      interventions,
    ];
    inputs.forEach((input) => {
      input.setters.setValue('');
      input.setters.setTouched(false);
      input.setters.setBlurred(false);
    });
  };

  return (
    <Container className={s.root}>
      <Card>
        <Headline
          wrapClassName={s.wrapHeadline}
          className={s.headline}
          underline
          center
          html="Hábitos y antecedentes médicos"
          h3
        />
        <form className={s.form} onSubmit={handleSubmit}>
          <div className={s.row}>
            <p dangerouslySetInnerHTML={{ __html: 'Tabáquicos:' }} />
            <Input
              placeholder="Unidades/Día"
              type="text"
              name="tabaq"
              value={tabaq.state.value}
              {...tabaq.events}
              error={tabaqErrors.tabaq}
              className={s.input}
            />
          </div>
          <div className={s.row}>
            <p dangerouslySetInnerHTML={{ __html: 'Bebidas Alcohólicas:' }} />
            <Input
              placeholder="Frecuencia"
              type="text"
              name="alcDrinks"
              value={alcDrinks.state.value}
              {...alcDrinks.events}
              error={alcDrinksErrors.alcDrinks}
              className={s.input}
            />
          </div>

          <div className={s.row}>
            <p dangerouslySetInnerHTML={{ __html: 'Alergias:' }} />
            <Input
              placeholder="Especificar"
              type="text"
              name="allergies"
              value={allergies.state.value}
              {...allergies.events}
              error={allergiesErrors.allergies}
              className={s.input}
            />
          </div>
          <div className={s.row}>
            <p dangerouslySetInnerHTML={{ __html: 'Medicación Actual:' }} />
            <Input
              placeholder="Especificar"
              type="text"
              name="actualMed"
              value={actualMed.state.value}
              {...actualMed.events}
              error={actualMedErrors.actualMed}
              className={s.input}
            />
          </div>
          <div className={s.row}>
            <p dangerouslySetInnerHTML={{ __html: 'Otros:' }} />
            <Input
              placeholder="Especificar"
              type="text"
              name="others"
              value={others.state.value}
              {...others.events}
              error={othersErrors.others}
              className={s.input}
            />
          </div>
          <div className={s.row}>
            <p dangerouslySetInnerHTML={{ __html: 'Intervenciones Ox:' }} />
            <Input
              placeholder="Especificar"
              type="text"
              name="interventions"
              value={interventions.state.value}
              {...interventions.events}
              error={interventionsErrors.interventions}
              className={s.input}
            />
          </div>
          <Headline h3 html="Patologías Previas" />
          <div className={s.checkboxes}>
            {patologiasArray.map((name, i) => (
              <div className={`${s.checkboxWrapper} ${i % 2 !== 0 ? s.odd : ''}`}>
                <Checkbox
                  key={`${name}-${i}`}
                  name={name}
                  setter={setPatologias}
                  values={patologias}
                  index={i}
                />
              </div>
            ))}
          </div>
          <Button className={s.button} type="submit" disabled={!submitReady}>
            Continuar
          </Button>
        </form>
      </Card>
    </Container>
  );
}
