import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta({
  title,
  description,
  canonical,
  ...other
}) {
  const ogImageUrl = 'https://insideeloy.org/images/287g.jpg';
  // const ogImageUrl = 'localhost:8000/images/287g.jpg';

  const ogImageAlt = 'Inside Elroy Illustration';

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {canonical && <meta property="og:url" content={canonical} />}
      {/* <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:secure_url" content={ogImageUrl} />
      <meta
        property="og:image:type"
        content="image/jpeg"
      />
      <meta
        property="og:image:width"
        content="1200"
      />
      <meta
        property="og:image:height"
        content="630"
      />
      <meta
        property="og:image:alt"
        content={ogImageAlt}
      />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" /> */}
      {/* <meta name="twitter:site" content="@adv_project" />
      <meta name="twitter:creator" content="@adv_project" /> */}
    </Helmet>
  );
}
