import React, { useState, useEffect } from 'react';
import s from './checkbox.module.scss';

export default function Checkbox({ name, setter, values, index, cleanup, ...props }) {
  const [checked, setChecked] = useState(null);
  const parsedName = name && name.replace(/ /gmi, '_');
  function handleChange() {
    setChecked(!checked);
    const newValues = values;
    newValues[name] = !checked;
    setter(newValues);
  }

  useEffect(() => {
    if (cleanup) setChecked(false);
  }, [cleanup]);
  return (
    <label htmlFor={name} className={s.checkbox} {...props}>
      {name}
      <input
        type="checkbox"
        id={name}
        checked={checked}
        onChange={handleChange}
        name={parsedName}
      />
      <span className={s.checkmark} />
    </label>
  );
}
