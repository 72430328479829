import React, { useState, useEffect } from 'react';
import Icon from 'icon';
import s from './step4.module.scss';

export function PreviewImage({ file, deleteImage }) {
  const isUploaded = typeof file === 'string';
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (!isUploaded) {
      let mounted = true;
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => {
        if (mounted) setSrc(fr.result);
      };
      // eslint-disable-next-line no-return-assign
      return () => (mounted = false);
    }
      setSrc(file);
  }, []);

  return src ? (
    <div className={s.previewImage}>
      <img src={src} alt="uploaded file" />
      <button
        type="button"
        aria-label="remove image"
        className={s.close}
        onClick={deleteImage}
      >
        <Icon icon="times" />
      </button>
    </div>
  ) : null;
}
